import { Dialog } from "primereact/dialog";
import { Hours, OpeningHours } from "../../api/merchants";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

interface props {
    merchantId: number;
    openingHours: OpeningHours;
    isOpen: boolean;
    onSave: (openingHours: OpeningHours) => void;
    onClose: () => void;
}

export const MerchantOpeningHoursModal: React.FC<props> = ({merchantId, openingHours, isOpen, onSave, onClose}) => {
    const [monday, setMonday] = useState<Hours[]>(openingHours.monday);
    const [tuesday, setTuesday] = useState<Hours[]>(openingHours.tuesday);
    const [wednesday, setWednesday] = useState<Hours[]>(openingHours.wednesday);
    const [thursday, setThursday] = useState<Hours[]>(openingHours.thursday);
    const [friday, setFriday] = useState<Hours[]>(openingHours.friday);
    const [saturday, setSaturday] = useState<Hours[]>(openingHours.saturday);
    const [sunday, setSunday] = useState<Hours[]>(openingHours.sunday);

    const header = 'Opening Hours';

    const updateTime = (start_time: string, end_time: string, index: number, dayOfWeek: number) => {
        if(!start_time.includes(':') || !end_time.includes(':')) {alert('Ungültige Zeitangabe'); return;}
        switch(dayOfWeek) {
            case 0:
                setMonday(monday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
            case 1:
                setTuesday(tuesday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
            case 2:
                setWednesday(wednesday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
            case 3:
                setThursday(thursday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
            case 4:
                setFriday(friday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
            case 5:
                setSaturday(saturday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
            case 6:
                setSunday(sunday.map((value, idx) => {return idx === index ? {start_time, end_time} : value}));
                break;
        }
    }

    const onSaveOpeningHours = () => {
        onSave({monday, tuesday, wednesday, thursday, friday, saturday, sunday});
        onClose();
    }


    return <Dialog 
        header={header} 
        visible={isOpen} 
        style={{width: '50vw'}} 
        onHide={onClose}>
        <form className="flex flex-col pt-2 w-full gap-4">
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Montag</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {monday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`monday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`monday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 0)} />
                            <div>-</div>
                            <InputText id={`monday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 0)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setMonday(monday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setMonday([...monday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Dienstag</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {tuesday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`tuesday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`tuesday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 1)} />
                            <div>-</div>
                            <InputText id={`tuesday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 1)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setTuesday(tuesday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setTuesday([...tuesday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Mittwoch</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {wednesday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`wednesday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`wednesday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 2)} />
                            <div>-</div>
                            <InputText id={`wednesday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 2)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setWednesday(wednesday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setWednesday([...wednesday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Donnerstag</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {thursday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`thursday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`thursday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 3)} />
                            <div>-</div>
                            <InputText id={`thursday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 3)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setThursday(thursday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setThursday([...thursday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Freitag</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {friday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`friday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`friday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 4)} />
                            <div>-</div>
                            <InputText id={`friday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 4)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setFriday(friday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setFriday([...friday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Samstag</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {saturday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`saturday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`saturday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 5)} />
                            <div>-</div>
                            <InputText id={`saturday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 5)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setSaturday(saturday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setSaturday([...saturday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
                <label htmlFor="calendar-monday" className="font-bold block mb-2">Sonntag</label>
                <div className="card flex flex-wrap gap-3 p-fluid">
                {sunday.map(({start_time, end_time}, index) => 
                <div className="flex" key={`sunday-${index}`} >
                    <div className="flex-col">
                            <InputText id={`sunday-start-${index}`} value={start_time} onChange={(e) => updateTime(`${e.target.value}`, end_time, index, 6)} />
                            <div>-</div>
                            <InputText id={`sunday-end-${index}`} value={end_time} onChange={(e) => updateTime(start_time, `${e.target.value}`,index, 6)} />
                    </div>
                    <Button icon="pi pi-times" onClick={(e) => {e.preventDefault(); setSunday(sunday.filter((_, idx) => idx !== index))}} />
                    </div>
                )}
                <Button icon="pi pi-plus" onClick={(e) => {e.preventDefault(); setSunday([...sunday, {start_time: '12:00', end_time: '15:00'}])}} />
                </div>
            </div>
            <div className="flex-auto">
            <button
                    onClick={(e) => {e.preventDefault(); onSaveOpeningHours()}}
                    className="mr-2 relative inline-flex items-center rounded-md bg-midblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                >
                    Speichern
                </button>
            </div>
        </form>
    </Dialog>
}