import { FC } from "react";
import { MerchantType } from "../../api/merchants";
import { Dialog } from "primereact/dialog";
import { Importer, ImporterField } from "react-csv-importer";
import { bulkUpdateMerchantProduct } from "../../api/products";
import 'react-csv-importer/dist/index.css';
import React from "react";

interface props {
    merchant?: MerchantType;
    isOpen: boolean;
    closeModal: () => void;
    refetch: () => void;
}

const BulkUpdateSockLevels: FC<props> = ({merchant, isOpen, closeModal, refetch}) => {
    const dataHandler = async (rows: any[]) => {
        if(!merchant) return;
        const stockLevels = rows.map(({sku, price, amount, triggerAmount, targetAmount}) => ({
            sku,
            price: price && price !== '' ? price : undefined,
            amount: amount && amount !== '' ? amount : undefined,
            triggerAmount: triggerAmount && triggerAmount !== '' ? triggerAmount : undefined,
            targetAmount: targetAmount && targetAmount !== '' ? targetAmount : undefined,
        }));
        await bulkUpdateMerchantProduct(`${merchant.id}`, {stockLevels});
        refetch();
        closeModal();
    };
    return <Dialog header={'Bestands CSV hochladen'} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
        <Importer dataHandler={dataHandler} onComplete={({ file, preview, fields, columnFields }) => {}} onClose={({ file, preview, fields, columnFields }) => {}}>
            <ImporterField name="sku" label="SKU" />
            <ImporterField optional name="price" label="Preis" />
            <ImporterField optional name="amount" label="Bestand" />
            <ImporterField optional name="triggerAmount" label="Triggerbestand" />
            <ImporterField optional name="targetAmount" label="Sollbestand" />
        </Importer>
    </Dialog>;
};
export default BulkUpdateSockLevels;