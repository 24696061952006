import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie"

export const getHeaders = () => {
    const cookies = new Cookies();
    const token = cookies.get("MIDNIGHT_DELIVERY_TOKEN");
    const headers = {Authorization: `Bearer ${token}`};
    return headers;
}

export const getRequest = async (url: string, params?: Record<string, any>): Promise<unknown> => {
    try {
        const headers = getHeaders();
        const {data} = await axios.get(`${process.env.REACT_APP_API_PATH}/${url}`, {headers, params});
        return data;
    } catch (error: any) {
        toast.error(error.message)
    }
}

export const postRequest = async (url: string, payload: any): Promise<unknown> => {
    try {
        const headers = getHeaders();
        const {data} = await axios.post(`${process.env.REACT_APP_API_PATH}/${url}`, payload, {headers});
        return data;
    } catch (error: any) {
        toast.error(error.message)
    }
}

export const putRequest = async (url: string, payload: any): Promise<unknown> => {
    try {
        const headers = getHeaders();
        const {data} = await axios.put(`${process.env.REACT_APP_API_PATH}/${url}`, payload, {headers});
        return data;
    } catch (error: any) {
        toast.error(error.message)
    }
}

export const patchRequest = async (url: string, payload?: any): Promise<unknown> => {
    try {
        const headers = getHeaders();
        const {data} = await axios.patch(`${process.env.REACT_APP_API_PATH}/${url}`, payload || {}, {headers});
        return data;
    } catch (error: any) {
        toast.error(error.message)
    }
}

export const deleteRequest = async (url: string): Promise<unknown> => {
    try {
        const headers = getHeaders();
        const {data} = await axios.delete(`${process.env.REACT_APP_API_PATH}/${url}`, {headers});
        return data;
    } catch (error: any) {
        toast.error(error.message)
    }
}