import {FC } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Logo from "../../../components/Logo";
interface IFormInput {
    password: string;
    repeatedPassword : string
}
const ResetPassword: FC = () => {

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm<IFormInput>();

    let {token} = useParams();
    const resetPasswordHandler = async (data : IFormInput)  => {
        try {
            await axios.post(`${process.env.REACT_APP_API_PATH}/auth/reset-password`,{token, password: data.password});
            toast.success("Das Passwort wurde erfolgreich geändert. Bitte melden Sie sich erneut an", {
                position: toast.POSITION.TOP_CENTER,
            });
        }catch (e) {
            toast.error("Etwas ist schief gelaufen. Bitte versuche es erneut", {
                position: toast.POSITION.TOP_CENTER,
                autoClose:2000
            });
        }
   }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <ToastContainer  autoClose={2000}/>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <Logo/>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-primary">
                    Setze dein Passwort zurück
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6"  onSubmit={handleSubmit(resetPasswordHandler)}>
                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-secondary-1">
                                Passwort
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                {...register("password",  { required : true , minLength : 6 })}
                                type="password"
                                autoComplete="current-password"
                                className="block w-full rounded-md border-0  p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-midblue sm:text-sm sm:leading-6"
                            />
                            {errors?.password?.type === "required"  &&   <p className={'text-red-500 m-2'}>Dieses Feld ist erforderlich </p>}
                            {errors?.password?.type === 'minLength' &&   <p className={'text-red-500 m-2'}>Das Mindestpasswort ist 6 Zeichen lang.</p>}
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-secondary-1">
                                Passwort wiederholen
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                type="password"
                                {...register("repeatedPassword",  {
                                    validate: (val: string) => {
                                        if (getValues('password') !== val) {
                                            return 'Ihre Passwörter stimmen nicht überein.'
                                        }
                                    }
                                })}
                                className="block w-full rounded-md border-0  p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-midblue sm:text-sm sm:leading-6"
                            />
                            {errors?.repeatedPassword?.type === "validate" &&   <p className={'text-red-500 m-2'}>{errors.repeatedPassword.message}</p>}
                        </div>
                    </div>

                    <div>
                        <input type="submit"
                          title={'Zurücksetzen'}
                               className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"/>
                    </div>
                </form>
            </div>
        </div>

    );
}
export default ResetPassword;
