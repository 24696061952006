import { FC, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { CSVLink } from "react-csv";
import { getProducts } from "../../api/products";
import 'react-csv-importer/dist/index.css';
import React from "react";

interface props {
    merchantId: number;
    isOpen: boolean;
    closeModal: () => void;
}

const BulkDownloadStockLevels: FC<props> = ({merchantId, isOpen, closeModal}) => {
    const [productCSV, setProductCSV] = React.useState<any[]>([]);

    useEffect(() => {
        const setProductData = async () => {
            const response = await getProducts({filters: {merchantId}, pagination: {page: 1, perPage: 1000, pageCount: 0, total: 0}});
            if(response) {
                const {data} = response;
                const products = data.map(({sku, price, amount, triggerAmount, targetAmount, title, tags}) => ({
                    sku: sku.split('_')[0],
                    price,
                    amount,
                    triggerAmount,
                    targetAmount,
                    title,
                    tags
                }));
                setProductCSV(products);
            }
        }
        if(productCSV.length === 0 && isOpen) setProductData();
    }, [isOpen, productCSV.length, merchantId]);
    const headerCSV = [
        {label: 'SKU', key: 'sku'},
        {label: 'Preis', key: 'price'},
        {label: 'Bestand', key: 'amount'},
        {label: 'Triggerbestand', key: 'triggerAmount'},
        {label: 'Sollbestand', key: 'targetAmount'},
        {label: "Titel", key: 'title'},
        {label: "Tags", key: 'tags'}
    ];
    return <Dialog header={'Bestands CSV runterladen'} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
        <CSVLink data={productCSV} headers={headerCSV} filename={'products.csv'}>CSV runterladen</CSVLink>
    </Dialog>;
};
export default BulkDownloadStockLevels;