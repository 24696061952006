import {Fragment, FC, useEffect, useMemo} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {Bars3Icon} from '@heroicons/react/24/outline'
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import Cookies from "universal-cookie";
import {useAppSelector, useAppDispatch} from "../../hooks/reduxHooks";
import {IUser, addUserData} from "../../redux/features/user/userSlice";
import { getRequest } from '../../api';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface HeaderProps {
    setSidebarOpen: (status: boolean) => void;
}

const Header: FC<HeaderProps> = ({setSidebarOpen}) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.userReducer.user);

    const cookies = useMemo(() => new Cookies(), []);
    const logout = () => {
        cookies.remove("MIDNIGHT_DELIVERY_TOKEN", {path: "/"});
        window.location.href = "/";
    }

    
    useEffect(() => {
        const getUser = async () => {
            const profileResponse = await getRequest('users/me') as IUser;
            dispatch(addUserData(profileResponse))
        }
        if(!user) {
            getUser();
        }
    }, [cookies, dispatch, user])
    return user ? (
        <div
            className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8  ">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
            </button>
            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>
            <div className="flex flex-1 gap-x-4 justify-end  lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                {user?.firstName + ' ' + user?.lastName}
                           </span>
                            <span className="flex items-center">
                                 <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </span>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items
                                className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                    {({active}) => (
                                        <button
                                            onClick={logout}
                                            className={classNames(
                                                active ? 'bg-gray-50' : '',
                                                'block px-3 py-1 text-sm leading-6 text-gray-900')}>
                                            Abmelden
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    ) : (
        <div
            className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8  ">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
            </button>
            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>
            <div className="flex flex-1 gap-x-4 justify-end  lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                    <a href='/login'>LOGIN</a>
                </div>
            </div>
        </div>
    )
}
export default Header
