import { BaseSyntheticEvent, FC, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { MerchantType, updateMerchant, createMerchant } from "../../api/merchants";
import { Dropdown } from "primereact/dropdown";

interface props {
    merchant?: MerchantType;
    isOpen: boolean;
    closeModal: () => void;
    refetch: () => void;
}
const EditMerchantModal: FC<props> = ({merchant, isOpen, closeModal, refetch}) => {
    const header = merchant ? 'Lager bearbeiten' : 'Lager anlegen';
    const initialValues = merchant ? merchant : {
        name: '',
        weclappId: '',
        orderlordId: '',
        latitude: 0,
        longitude: 0,
        reorderDay: 0,
        maxDeliveryDistance: 25,
        isOpen: false,
        minOrderPrice: 1700,
        orderDistanceFactor: 2.5,
        orderTimeFactor: 2.5,
    }
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<MerchantType>({defaultValues: initialValues})
    useEffect(() => {
        if(merchant) {
            reset(merchant);
        }
    }, [merchant, reset])

    const onSubmit = async (data: FieldValues, e?: BaseSyntheticEvent) => {
        try {
            if (merchant) {
                await updateMerchant({id: merchant.id, ...data as MerchantType});
            } else {
                await createMerchant(data as MerchantType);
            }
            refetch();
            closeModal();
        } catch (err) {
            toast.error(err as string);
        }
    };

    const reorderDayOptions = [
        {label: 'Montag', value: 1},
        {label: 'Dienstag', value: 2},
        {label: 'Mittwoch', value: 3},
        {label: 'Donnerstag', value: 4},
        {label: 'Freitag', value: 5},
        {label: 'Samstag', value: 6},
        {label: 'Sonntag', value: 0},
    ]

    return (
        <Dialog header={header} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col pt-2 w-full gap-4">
                    <div className="w-full pt-4 py-2 flex flex-col justify-between md:flex-row gap-4">
                    <div className="w-full">
                    <Controller name={"name"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.name })}></label>
                            <span className="p-float-label w-full">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Lager Name</label>
                            </span>
                        </>
                    )} />
                    </div>
                    <div className="w-full">
                    <Controller name={"reorderDay"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.name })}></label>
                            <span className="p-float-label w-full">
                                <Dropdown id={field.name} value={field.value} options={reorderDayOptions} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Bestelltag</label>
                            </span>
                        </>
                    )} />
                    </div>
                    </div>

                    <div className="w-full py-2 flex flex-col justify-between md:flex-row gap-4">
                        <div className="w-full">
                            <Controller name={"latitude"} control={control} render={({ field, fieldState }) => (
                                <>
                                    <span className="p-float-label">
                                        <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                        <label htmlFor={field.name}>Breitengrad</label>
                                    </span>
                                </>
                            )} />
                        </div>
                        <div className="w-full">
                            <Controller name={"longitude"} control={control} render={({ field, fieldState }) => (
                                <>
                                    <label htmlFor={field.name} className={classNames({ 'p-error': errors.longitude })}></label>
                                    <span className="p-float-label">
                                        <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                        <label htmlFor={field.name}>Längengrad</label>
                                    </span>
                                </>
                            )} />
                        </div>
                    </div>

                    <div className="w-full py-2 flex flex-col justify-between md:flex-row gap-4">
                    <div className="w-full">
                    <Controller name={"orderlordId"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.orderlordId })}></label>
                            <span className="p-float-label">
                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>OrderlordId</label>
                            </span>
                        </>
                    )} />
                    </div>
                    <div className="w-full">
                    <Controller name={"weclappId"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.weclappId })}></label>
                            <span className="p-float-label">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>WeclappId</label>
                            </span>
                        </>
                    )} />
                    </div>
                    </div>

                    <div className="w-full py-2 flex flex-col justify-between md:flex-row gap-4">
                    <div className="w-full">
                    <Controller name={"orderDistanceFactor"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.orderlordId })}></label>
                            <span className="p-float-label">
                            <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                <label htmlFor={field.name}>Distance Factor</label>
                            </span>
                        </>
                    )} />
                    </div>
                    <div className="w-full">
                    <Controller name={"orderTimeFactor"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.weclappId })}></label>
                            <span className="p-float-label">
                                <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                <label htmlFor={field.name}>Time Factor</label>
                            </span>
                        </>
                    )} />
                    </div>
                    <div className="w-full">
                    <Controller name={"minOrderPrice"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.weclappId })}></label>
                            <span className="p-float-label">
                                <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                <label htmlFor={field.name}>Mindestbestellmenge in Rappen</label>
                            </span>
                        </>
                    )} />
                    </div>
                    </div>
                    
                    <Button className="mt-2 p-4 bg-primary flex justify-center items-center text-white" label="Speichern" type="submit" icon="" />
                </form>
        </Dialog>
    )
}

export default EditMerchantModal;