import React, {useState, FC , FormEvent} from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
const Index: FC = () => {
    const [email,setEmail] = useState<string>('');
    const handleSubmit = async (e: FormEvent)  => {
        e.preventDefault();
        try {
              await axios.post(`${process.env.REACT_APP_API_PATH}/auth/request-password`,{email});
              toast.success("Falls wir Ihre E-Mail-Adresse haben, werden wir Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts senden.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }catch (e) {
            toast.error("Es gibt einen Fehler. Bitte überprüfen Sie die E-Mail oder versuchen Sie es später erneut.", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    }
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <ToastContainer autoClose={6000} />
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-primary">
                    Passwort zurücksetzen
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="auth/Login#" method="POST" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-secondary-1">
                            E-Mail-Adresse
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border-0  p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-midblue sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue">
                            Senden
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
}

export default Index;
